import { useState } from 'react';
import { Colors } from '../utils/Colors.js'
import { Sizes } from '../utils/Sizes.js';

export function Text({text, textColor, fontSize, fontWeight, fontStyle, margin}) {
    return(
        <>
            <p style={{
                color: textColor,
                fontSize: fontSize,
                fontWeight: fontWeight,
                fontFamily: "Exo 2",
                fontStyle: fontStyle,
                margin: margin,
            }}
            >{text}</p>
        </>
    );
}

export function PrimaryButton({text, action, margin}) {
    const [hover, setHover] = useState(false)

    function handleHover() {
        setHover(!hover)
    }

    return(
        <>
            <button 
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                style={{
                    backgroundColor: Colors.android,
                    border: 0,
                    paddingLeft: Sizes.Button.Padding.horizontal,
                    paddingRight: Sizes.Button.Padding.horizontal,
                    paddingTop: Sizes.Button.Padding.vertical,
                    paddingBottom: Sizes.Button.Padding.vertical,
                    borderRadius: hover ? Sizes.Button.Radius.small : Sizes.Button.Radius.normal,
                    boxShadow: hover ? setHoverShadow() : setShadow(),
                    transform: hover ? setHoverTransform() : "",
                    fontFamily: "Exo 2",
                    fontSize: Sizes.Text.normal,
                    fontWeight: hover ? Sizes.Text.Weight.bold : Sizes.Text.Weight.normal,
                    margin: margin,
                }} 
                onClick={action}>{text}
            </button>
        </>
    )
}

export function SecondaryButton({text, action, margin}) {
    const [hover, setHover] = useState(false)

    function handleHover() {
        setHover(!hover)
    }

    return(
        <>
            <button 
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                style={{
                    color: Colors.android,
                    backgroundColor: Colors.transparent,
                    paddingLeft: Sizes.Button.Padding.horizontal,
                    paddingRight: Sizes.Button.Padding.horizontal,
                    paddingTop: Sizes.Button.Padding.vertical,
                    paddingBottom: Sizes.Button.Padding.vertical,
                    border: "1px solid " + Colors.android,
                    borderColor: Colors.android,
                    borderRadius: hover ? Sizes.Button.Radius.small : Sizes.Button.Radius.normal,
                    boxShadow: hover ? setHoverShadow() : setShadow(),
                    transform: hover ? setHoverTransform() : "",
                    fontFamily: "Exo 2",
                    fontSize: Sizes.Text.normal,
                    fontWeight: hover ? Sizes.Text.Weight.bold : Sizes.Text.Weight.normal,
                    margin: margin,
                }} 
                onClick={action}>{text}
            </button>
        </>
    )
}

export function DangerButton({text, action, margin}) {
    const [hover, setHover] = useState(false)

    function handleHover() {
        setHover(!hover)
    }

    return(
        <>
            <button 
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                style={{
                    color: Colors.white,
                    backgroundColor: Colors.danger,
                    border: 0,
                    paddingLeft: Sizes.Button.Padding.horizontal,
                    paddingRight: Sizes.Button.Padding.horizontal,
                    paddingTop: Sizes.Button.Padding.vertical,
                    paddingBottom: Sizes.Button.Padding.vertical,
                    borderRadius: hover ? Sizes.Button.Radius.small : Sizes.Button.Radius.normal,
                    boxShadow: hover ? setRedHoverShadow() : setRedShadow(),
                    transform: hover ? setHoverTransform() : "",
                    fontFamily: "Exo 2",
                    fontSize: Sizes.Text.normal,
                    fontWeight: hover ? Sizes.Text.Weight.bold : Sizes.Text.Weight.normal,
                    margin: margin
                }} 
                onClick={action}>{text}
            </button>
        </>
    )
}

export function InfoButton({text, action, margin}) {
    const [hover, setHover] = useState(false)

    function handleHover() {
        setHover(!hover)
    }

    return(
        <>
            <button 
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                style={{
                    color: Colors.white,
                    backgroundColor: Colors.info,
                    border: 0,
                    paddingLeft: Sizes.Button.Padding.horizontal,
                    paddingRight: Sizes.Button.Padding.horizontal,
                    paddingTop: Sizes.Button.Padding.vertical,
                    paddingBottom: Sizes.Button.Padding.vertical,
                    borderRadius: hover ? Sizes.Button.Radius.small : Sizes.Button.Radius.normal,
                    boxShadow: hover ? setBlueHoverShadow() : setBlueShadow(),
                    transform: hover ? setHoverTransform() : "",
                    fontFamily: "Exo 2",
                    fontSize: Sizes.Text.normal,
                    fontWeight: hover ? Sizes.Text.Weight.bold : Sizes.Text.Weight.normal,
                    margin: margin
                }} 
                onClick={action}>{text}
            </button>
        </>
    )
}

export function HorizontalSpacer({size}) {
    return <div style={{display: 'inline-block', width: size}} />;
}

export function VerticalSpacer({size}) {
    return <div style={{display: 'block', height: size}} />;
}

export function setShadow() {
    return "0px 3px 6px 0px rgba(50, 222, 132, 0.2)"
}

export function setHoverShadow() {
    return "0px 6px 8px 1px rgba(50, 222, 132, 0.3)"
}

export function setRedShadow() {
    return "0px 3px 6px 0px rgba(219, 50, 77, 0.4)"
}

export function setRedHoverShadow() {
    return "0px 6px 8px 1px rgba(219, 50, 77, 0.5)"
}

export function setBlueShadow() {
    return "0px 3px 6px 0px rgba(0, 157, 219, 0.4)"
}

export function setBlueHoverShadow() {
    return "0px 6px 8px 1px rgba(0, 157, 219, 0.5)"
}

export function setHoverTransform() {
    return "translateY(1px)"
}

export function setBoldText() {
    return "bold"
}

export function SolidCard({title, description, button, isButtonPrimary}) {
    return(
        <>
            <div style={{
                textAlign: "center",
                padding: Sizes.Card.padding,
                backgroundColor: Colors.android,
                width: Sizes.Card.Size.normal,
                height: Sizes.Card.Size.normal
            }}>
                <p>{title}</p>
                <p>{description}</p>
                {button ? 
                    isButtonPrimary ? 
                        <PrimaryButton text="Primary Button" action={console.log("Primary button pressed")} /> 
                        : <SecondaryButton text="Secondary Button" action={console.log("Secondary button pressed")} /> 
                    : <></>
                }
            </div>
        </>
    )
}

export function OutlinedCard() {

}

export function LovedSkills() {
    return(
        <div style={{
            backgroundColor: Colors.androidalpha,
            width: "fit-content",
            margin: "12px 0px 0px 48px",
            padding: "12px",
            borderRadius: Sizes.Button.Radius.normal
        }}>
            <a href="https://skillicons.dev">
                <img alt="Loved skills icons" src="https://skillicons.dev/icons?i=kotlin,dart,flutter,js,godot,react,cs,gradle,firebase,gcp,git,github,idea,vscode,materialui,figma,mongodb,bots,arduino,discord,stackoverflow&perline=7"/>
            </a>
        </div>
    )
}

export function Skills() {
    return(
        <div style={{
            backgroundColor: Colors.androidalpha,
            width: "fit-content",
            margin: "12px 0px 0px 48px",
            padding: "12px",
            borderRadius: Sizes.Button.Radius.normal,
            justifyContent: "center",
            alignItems: "center",
        }}>
            <a href="https://skillicons.dev">
                <img alt="Skills icons" src="https://skillicons.dev/icons?i=kotlin,java,dart,flutter,js,ts,godot,unity,react,dotnet,cs,cpp,nodejs,nestjs,html,css,jquery,php,postman,gradle,maven,heroku,firebase,gcp,md,git,github,stackoverflow,androidstudio,idea,vscode,visualstudio,atom,materialui,figma,xd,postgres,mongodb,mysql,sqlite,arduino,discord,bots&perline=11"/>
            </a>
        </div>
    )
}