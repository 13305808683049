export const Sizes = {
    Button: {
        Padding: {
            horizontal: 24,
            vertical: 12, 
        },
        Radius: {
            normal: 18,
            small: 12
        }
    },
    Spacing: {
        Horizontal: {
            normal: 16,
            double: 32,
            triple: 48,
        },
        Vertical: {
            normal: 32,
            double: 64,
            triple: 96
        }
    },
    Text: {
        small: 18,
        normal: 22,
        big: 26,
        massive: 30,
        title: 34,
        Weight: {
            light: 200,
            normal: 400,
            bold: 600
        }
    },
    Card: {
        padding: 20,
        Size: {
            normal: 64,
            large: 96
        }
    }
}