import './App.css';
import { Colors } from './utils/Colors.js'
import { Text, Skills, LovedSkills } from './components/Base';
import { Sizes } from './utils/Sizes.js';

function App() {
  return (
    <div className="App">

      <Text
        text={"Hello, I'm Davide 🧑🏼‍💻"} 
        textColor={Colors.android} 
        fontSize={Sizes.Text.title}
        fontWeight={Sizes.Text.Weight.bold}
        margin={"48px 0px 0px 48px"} />

      <Text
        text={"🍕 🍝 🤌🏻"} 
        textColor={Colors.android} 
        fontSize={Sizes.Text.massive}
        fontWeight={Sizes.Text.Weight.normal}
        margin={"12px 0px 0px 48px"} />

      <Text
        text={"What I love 💖"}
        textColor={Colors.android}
        fontSize={Sizes.Text.massive}
        fontWeight={Sizes.Text.Weight.bold}
        margin={"24px 0px 0px 48px"} />

      <LovedSkills />

      <Text
        text={"Everything I worked with 📚"}
        textColor={Colors.android}
        fontSize={Sizes.Text.massive}
        fontWeight={Sizes.Text.Weight.bold}
        margin={"24px 0px 0px 48px"} />

      <Skills />
    
    </div>
  );
}

export default App;
